// theme-class.directive.ts
import { Directive, ElementRef, Input, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { ThemeService } from '../_services/theme.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appThemeClass]'
})
export class ThemeClassDirective implements OnInit, OnDestroy {
  @Input('appThemeClass') themeClass: string; // Input for theme-specific class key
  private themeSubscription: Subscription;
  private currentThemeClass: string; // Track the currently applied class

  constructor(
    private el: ElementRef,
    private themeService: ThemeService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    // Subscribe to theme changes
    this.themeSubscription = this.themeService.currentMode$.subscribe(classes => {
      const themeClassValue = classes[this.themeClass]; // Get the actual class name
      if (themeClassValue) {
        // Remove previously applied theme class, if any
        if (this.currentThemeClass) {
          this.renderer.removeClass(this.el.nativeElement, this.currentThemeClass);
        }
        // Apply the new theme class
        this.renderer.addClass(this.el.nativeElement, themeClassValue);
        // Update the tracker with the new class name
        this.currentThemeClass = themeClassValue;
      }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks
    if (this.themeSubscription) {
      this.themeSubscription.unsubscribe();
    }
  }
}
