import { Component, OnInit, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
//import { DatePipe } from '@angular/common';
import { Helpers } from '../../../helpers';
import { JiraService } from '../../../_services/jira.service';
import { GlobalEventsManagerService } from '../../../global-event-manager-service.service';
import { UserService } from '../../../auth/_services/user.service';
import swal from 'sweetalert2';
import jwt from 'jsonwebtoken';
import { IssuesHelper } from '../../../helpers/issuesHelper';
import { IssuesService } from '../../../_services/Issues.service';
import { AlertMessagesService } from '../../../_services/alertMessages.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ThemeService } from '../../../_services/theme.service';

declare let mLayout: any;
declare let $: any;

@Component({
    selector: "app-header-nav",
    templateUrl: "./header-nav.component.html",
    styleUrls: ["./header-nav.component.css"],
    encapsulation: ViewEncapsulation.None,
    providers: [JiraService, IssuesService, IssuesHelper, AlertMessagesService]
})
export class HeaderNavComponent implements OnInit, AfterViewInit, OnDestroy {
    subs: Subscription[] = [];
    authenticationSubscription: any;
    munitSelected: boolean = false;
    showMunit: boolean = true;
    showNavBar: boolean = false;
    showSuperAdminBar: boolean = false;
    isInProcess = true;
    showManagerData: boolean = false;
    showMemberData: boolean = false;
    logindata: any = null;
    public Alerts: any = [];
    public logging: any = [];
    Secuirtybox: boolean = true;
    public firstname;
    public lastname;
    public email;
    public project;
    public hostName: any;
    public AvgRisk: any | 0;
    public avgRiskValue: any;
    public uniqueIpsCount = 0;
    public alertMessages: any = [];
    public selectedMunits: any = [];
	public isManager;
	public isMember;
	public isAdmin;
	public projectSettings;
	public projectKey;
	public licenseKey;
    public licenseActivationDate;
	public licenseClass;
	public userToken;
	public isLicenseActive;
	public activationMessage;
	public remainingInterval;
    currentClasses: any;
    isDarkMode: boolean = false;
    constructor(private themeService: ThemeService, private userService: UserService, private alertMessagesService: AlertMessagesService, private issueService: IssuesService, private _jiraService: JiraService, private _globalEventService: GlobalEventsManagerService) {
        this.selectedMunits = [];
        this._jiraService.getAlerts().subscribe(res => {
            this.Alerts = res;
        });
        this._jiraService.getUserLogging().subscribe(res => {
            // this._jiraService.getLogging().subscribe(res => {
            this.logging = res.splice(0,5);
        });
        this.subscribeToAuthentication();
        setInterval(() => {
            this.securityBox();
        }, 300000);

        
        setTimeout(() => {
            this.getAlertMessages();
        }, 5000);


    }
    getAlertMessages() {
        this.alertMessagesService.GetAlertMessages().subscribe(messages => {
            this.alertMessages = [];
            for (let i = 0; i < messages.length; i++) {
                let valid: any = new Date(messages[i].ToDate);
                let now: any = new Date();

                let isUserEligible = false;
                for (let j = 0; j < messages[i].users.length; j++) {
                    let element = messages[i].users[j];
                    if (element === this.email) {
                        isUserEligible = true;
                    }

                }
                if (valid > now && this.project === messages[i].project || valid > now && isUserEligible) {
                    this.alertMessages.push(messages[i]);
                }
            }
        });
    }


    securityBox() {
        this._jiraService.getSecurityBox().subscribe(res => {
            if (res == "false") {
                this.Secuirtybox = false;
            }
            else {
                this.hostName = res.hostName;
                this.Secuirtybox = true;
            }
        });
    }

    ngOnInit() {
        this._globalEventService.selectedMunitsEmitter.subscribe(resp => {
            if (resp) {
                this.munitSelected = false;
                this.selectedMunits = [];
                this.selectedMunits = resp;
                this.munitSelected = true;
                if (this.selectedMunits.length > 1) {
                    this.showMunit = false;
                }
                if (this.selectedMunits.length == 1 && this.selectedMunits[0] === "ALL") {
                    this.munitSelected = false;
                }
            }
        });
        this._globalEventService.alertMessagesEmitter.subscribe(shouldGet => {
            if (shouldGet === "true") {
                this.getAlertMessages();
            }
        });
        this.themeService.currentMode$.subscribe(classes => {
            this.currentClasses = classes;
          });
    }
    ngAfterViewInit() {
        mLayout.initHeader();

        this._globalEventService.companyRiskEmitter.subscribe(risk => {
            if (risk) {
                this.isInProcess = false;
                if (risk >= 0 && risk <= 27) {
                    $('.avgRisk').addClass('lowRisk');
                    this.AvgRisk = "Low";
                }
                else if (risk >= 28 && risk <= 63) {
                    $('.avgRisk').addClass('mediumRisk');
                    this.AvgRisk = "Medium";
                }
                else if (risk >= 64 && risk < 90) {
                    $('.avgRisk').addClass('highRisk');
                    this.AvgRisk = "High";
                }
                this.avgRiskValue = risk.toFixed(2);
            }
        });

    }

    subscribeToAuthentication() {
        if (localStorage.getItem('isUserLoggedIn') == 'true') {
            let currentuser = localStorage.getItem('currentUser');
            let Fetchtoken = JSON.parse(currentuser);

            let Token = Fetchtoken.token;
			this.userToken=Token;
            let authenticationSubscription = this.userService.getUserInformation(Token).subscribe(res => {
                this.isDarkMode=res.themeMode;
                this.themeService.toggleMode(this.isDarkMode);
                this.firstname = res.firstname;
                this.lastname = res.lastname;
                this.email = res.email;
                this.project = res.projectName;
                if (res.role == 'Super') {

                    this._globalEventService.showSuperAdminBar(true);
                    this._globalEventService.showSuperAdminBarEmitter.subscribe((mode: any) => {
                        // mode will be null the first time it is created, so you need to igonore it when null
                        if (mode !== null) {
                            this.showSuperAdminBar = mode;
                        }
                    });
                } else if (res.role == 'Admin') {

                    this._globalEventService.showNavBar(true);
                    this._globalEventService.showNavBarEmitter.subscribe((mode: any) => {
                        // mode will be null the first time it is created, so you need to igonore it when null
                        if (mode !== null) {
                            this.showNavBar = mode;
                        }
                    });
                } else if (res.role == 'Manager') {

                    this._globalEventService.showManagerData(true);
                    let obs = this._globalEventService.showManegerEmitter.subscribe((mode: any) => {
                        // mode will be null the first time it is created, so you need to igonore it when null
                        if (mode !== null) {
                            this.showManagerData = mode;
                        }
                        this.subs.push(obs);

                    });

                } else if (res.role == 'Member') {

                    this._globalEventService.showMemberData(true);
                    this._globalEventService.showMemberEmitter.subscribe((mode: any) => {
                        // mode will be null the first time it is created, so you need to igonore it when null
                        if (mode !== null) {
                            this.showMemberData = mode;
                        }
                    });
                }
                this.subs.push(authenticationSubscription);
            });
			//console.log(Token);
			this._jiraService.getUserProjectSettings(Token).subscribe(result => {
				this.projectSettings = result;
				this.projectKey=result.licenseInfo.projectKey;
				//console.log(result);
				if(result.role=='Manager'){
					this.isManager=true;
				} else if(result.role=='Member'){
					this.isMember=true;
				}else if(result.role=='Admin'){
					this.isAdmin=true;
				}
				
				this.isLicenseActive=result.licenseInfo.isValidated;
				this.licenseKey =result.licenseInfo.licenseKey;
                let dateString = result.licenseInfo.startDate;
                const date = new Date(dateString);
                const day = date.getUTCDate();
                const month = date.toLocaleString('en-US', { month: 'short' });
                const year = date.getUTCFullYear();

                this.licenseActivationDate = `${day} ${month} ${year}`;
				if(result.licenseInfo.isValidated==false){
					this.activationMessage='Your project ('+result.projectName[0].name+') is not activated yet, please contact manager to activate project key!';
				}
				if(result.licenseInfo.remainingInterval){
					if(result.licenseInfo.remainingInterval>0){
						let licEnding=result.licenseInfo.endingDate;
						if(result.licenseInfo.remainingInterval<=30){
							this.licenseClass=1;
						}else if(result.licenseInfo.remainingInterval>30 && result.licenseInfo.remainingInterval<=90){
							this.licenseClass=2;
						}else if(result.licenseInfo.remainingInterval>90){
							this.licenseClass=3;
						}
						let rmessage = 'Your license key ('+result.licenseInfo.licenseKey+') is expiring  after '+result.licenseInfo.remainingInterval+' days.';
						
						this.remainingInterval =result.licenseInfo.remainingInterval;
					}
					
				}
			});
			
        }



        // this._globalEventService.showNavBar(true);
        // this._globalEventService.showNavBarEmitter.subscribe((mode:any)=>{
        //         // mode will be null the first time it is created, so you need to igonore it when null
        //         if (mode !== null) {
        //           this.showNavBar = mode;
        //         }
        //     });

        // this._globalEventService.loginDataEmitter.subscribe((loginData:any)=>{

        //         // mode will be null the first time it is created, so you need to igonore it when null
        //         if (loginData !== null) {

        //           this.logindata = loginData;
        //         }
        //     });
    }

    alertModel(source: any, date: any, description: any, recommendation: any, tags: any) {

        let html = "<h5><strong>Source</strong></h5>\
          <p>"+ source + "</p>\
          <h5><strong>Date</strong></h5>\
          <p>"+ date + "</p>\
          <h5><strong>Description</strong></h5>\
          <p>"+ description + "</p>\
          <h5><strong>Recommendation</strong></h5>\
          <p>"+ recommendation + "</p>\
          <h5><strong>Tags</strong></h5>\
          <p>"+ tags + "</p>"
        $(".modal-body").html(html);
        $("#myModal").modal('show');
    }
    ngOnDestroy(): void {
        this.subs.forEach(s => {
            if (s) {
                s.unsubscribe()
            }
        });
        // this.authenticationSubscription.unsubscribe();
    }

}
