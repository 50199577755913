import {
    Component,
    ComponentFactoryResolver,
    OnInit,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScriptLoaderService } from '../_services/script-loader.service';
import { AuthenticationService } from './_services/authentication.service';
import { AlertService } from '../_services/alert.service';
import { UserService } from './_services/user.service';
import { AlertComponent } from './_directives/alert.component';
import { LoginCustom } from './_helpers/login-custom';
import { Helpers } from '../helpers';
import { Ng2IzitoastService } from 'ng2-izitoast';
declare var $: any;
@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './templates/login-1.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [Ng2IzitoastService]
})

export class AuthComponent implements OnInit {
    model: any = {};
    loading = false;
    returnUrl: string;

    @ViewChild('alertSignin',
        { read: ViewContainerRef }) alertSignin: ViewContainerRef;
    @ViewChild('alertSignup',
        { read: ViewContainerRef }) alertSignup: ViewContainerRef;
    @ViewChild('alertForgotPass',
        { read: ViewContainerRef }) alertForgotPass: ViewContainerRef;
    @ViewChild('alertOTPass',
    { read: ViewContainerRef }) alertOTPass: ViewContainerRef;
    email: string;
    password: string;

    constructor(
        private _iziToast: Ng2IzitoastService,
        private _router: Router,
        private _script: ScriptLoaderService,
        private _userService: UserService,
        private _route: ActivatedRoute,
        private _authService: AuthenticationService,
        private _alertService: AlertService,
        private cfr: ComponentFactoryResolver) {

    }


    ngOnInit() {
        this.model.remember = false;
        // get return url from route parameters or default to '/'
        this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
        this._router.navigate([this.returnUrl]);

        this._script.loadScripts('body', [
            'assets/vendors/base/vendors.bundle.js',
            'assets/demo/default/base/scripts.bundle.js'], true).then(() => {
                Helpers.setLoading(false);
                LoginCustom.init();
                this.showbackground();
            });
    }
    showbackground() {
        // var bgArray = ['bg1.jpg', 'bg-6.png', 'bg-4.jpg', 'bg-2.jpg', 'bg-5.jpg', 'business.jpg'];
        var bgArray = ['bg8.png'];
        var bg = bgArray[Math.floor(Math.random() * bgArray.length)];
		bg = 'bg8.png';
        var path = './assets/app/media/img/bg/';
        var imageUrl = path + bg;
        $('.jsBackground').css('background-image', 'url(' + imageUrl + ')');
        $(".jsBackground").css("background-color", "#1f252b");
    }
    signin() {
        this.loading = true;
        // console.log(this.model);
        
        this._authService.login(this.model).subscribe(
            data => {
                if (data.errArray) {
                    for (let j = 0; j < data.errArray.length; j++) {
                        this._iziToast.show({ title: "Error", message: `${JSON.stringify(data.errArray[j]) }.`, color: "red" });
                        // const element = resp.error[j];                    
                    }    
                } else if (data === "UserFound") {
                    setTimeout(() => {

                        this._router.navigate([this.returnUrl]);
                    }, 600);
                } else if (data.error) {
                    this._iziToast.show({
                        title: "Error",
                        color: "red",
                        timeout:999999999,
                        message: data.error.message
                    });
                    this.showAlert('alertSignin');
                    this.loading = false;
                }
                 else {
                    this._iziToast.show({
                        title: "Error",
                        color: "red",
                        message: "Please Enter Valid UserName or Password"
                    });
                    this.showAlert('alertSignin');
                    this.loading = false;
                }

            },
            error => {
                this.showAlert('alertSignin');
                this._alertService.error(error);
                this.loading = false;
            });

    }

    signup() {
        this.loading = true;
        this._userService.create(this.model).subscribe(
            data => {
                this.showAlert('alertSignin');
                this._alertService.success(
                    'Thank you. To complete your registration please check your email.',
                    true);
                this.loading = false;
                LoginCustom.displaySignInForm();
                this.model = {};
            },
            error => {
                this.showAlert('alertSignup');
                this._alertService.error(error);
                this.loading = false;
            });
    }

    //genarates otp
    onetimepassword() {
        this.loading = true;
        this.email = this.model.email.toLowerCase()
        // send otp request and email
        this._userService.forgotPasswordOTP(this.model.email).subscribe(
            data => {
                this.showAlert('alertOTPass');
                this._alertService.success(
                    'Cool! Password recovery instruction has been sent to your email.',
                    true);
                this.loading = false;
                // LoginCustom.displaySignInForm();
                LoginCustom.displayforgotpasswordOTPForm();
                this.model = {};
            },
            error => {
                this.showAlert('alertForgotPass');
                this._alertService.error(error);
                this.loading = false;
            });
    }

    //genarates otp for login
    loginonetimepassword() {
        this.loading = true;
        this.email = this.model.email.toLowerCase()
        this.password = this.model.password;
        // send otp request and email
        this._authService.onlyAuthLogin(this.model).subscribe(res => {
            let error = res['error'] as any;
            if (res != 'ok') {
                this.loading = false;
                this._iziToast.show({
                    title: "Error",
                    color: "red",
                    message: error.message||" Something went wrong!"
                });
                LoginCustom.displaySignInForm();
            }
            else {
                this._userService.loginOTP(this.model.email).subscribe(
                    data => {
                        if (data == 'error') {
                            this._iziToast.show({
                                title: "Error",
                                color: "red",
                                message: "Something went wrong! retry."
                            });
                            LoginCustom.displaySignInForm();
                        }
                        this.loading = false;
                        // LoginCustom.displaySignInForm();
                        LoginCustom.displayOTPForm();
                        // this.model = {};
                    },
                    error => {
                        this.showAlert('alertSignin');
                        this._alertService.error(error);
                        this.loading = false;
                    });

            }
        })
       
    }

    forgotPass() {
        this.loading = true;
        //send otp and password for verification
        this.model['email'] = this.email
        
        this._userService.forgotPassword(this.model).subscribe(
            data => {
                if(data!='ok') {                    
                    
                    this._iziToast.show({
                            title: "Error",
                            color: "red",
                            message: JSON.stringify(data)
                        });
                    this.loading = false;
                }else{

                    this.loading = false;
                    LoginCustom.displaySignInForm();
                    
                    this.model = {};
                    this.email = undefined
                }

            },
            error => {
                this.showAlert('alertOTPass');
                this._alertService.error(error);
                this.loading = false;
            });
    }

    showAlert(target) {
        this[target].clear();
        let factory = this.cfr.resolveComponentFactory(AlertComponent);
        let ref = this[target].createComponent(factory);
        ref.changeDetectorRef.detectChanges();
    }
}