import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from "../auth/_guards/auth.guard";

var expectedRole: any = [];
const routes: Routes = [
    {
        "path": "",
        "component": ThemeComponent,
        "canActivate": [AuthGuard],

        "children": [

            {
                "path": "index",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Super','Manager', 'Admin', 'Member']
                },
                "loadChildren": ".\/pages\/default\/index\/index.module#IndexModule",
            },


            {
                "path": "add-user",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Super' ,'Admin']
                },
                "loadChildren": ".\/pages\/default\/components\/add-user\/add-user.module#AddUserModule",

            },
            //  AlienVault component

            {
                "path": "alien-vault-details/:id",
                "canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/default\/components\/alienvault-alarm\/alienvault.module#AlienVaultModule",

            },
            // end AlienVault 

            //  AlienVault Setting component

            {
                "path": "alien-vault-setting/:project",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Super']
                },
                "loadChildren": ".\/pages\/default\/components\/alienvault-setting\/alienvault-setting.module#AlienVaultSettingModule",

            },
            // end AlienVault Setting

            // inventory component
            {
                "path": "inventory",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: [ 'Pentester', 'Admin', 'Manager']
                },
                "loadChildren": ".\/pages\/default\/components\/inventory-list\/inventory-list.module#InventoryListModule",
            },
            // end inventory component 

            // inventory files component
            {
                "path": "inventory-files/:id",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Pentester', 'Admin', 'Manager']
                },
                "loadChildren": ".\/pages\/default\/components\/inventory-files\/inventory-files.module#InventoryFilesModule",
            },
            // end inventory files component

            // inventory files component
            {
                "path": "inventory-assets/:id",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Pentester', 'Admin', 'Manager']
                },
                "loadChildren": ".\/pages\/default\/components\/assets-list\/assets-list.module#AssetsListModule",
            },
            // end inventory files component

            // inventory files component
            {
                "path": "business-Impact-formulation/:id",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Pentester', 'Admin']
                },
                "loadChildren": ".\/pages\/default\/components\/business-Impact-formulation\/business-Impact-formulation.module#BusinessImpactFormulationModule",
            },
            // end inventory files component

            // calender component
            {
                "path": "calender",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Super' ,'Manager', 'Pentester', 'Admin', 'Member']
                },
                "loadChildren": ".\/pages\/default\/components\/calender-component\/calender.module#CalenderModule",
            },
            // end calender component

            //  SLA-Setting component

            {
                "path": "sla-setting",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Manager', 'Pentester', 'Admin', 'Member']
                },
                "loadChildren": ".\/pages\/default\/components\/sla-setting\/sla-setting.module#SLAModule",

            },
            // end SLA-Setting 

             //  Sessions component

             {
                "path": "sessions",
                "canActivate": [AuthGuard],
                data: {
                    // expectedRole: ['Manager', 'Pentester', 'Admin', 'Member'],
                    exRole:['2FA']
                },
                "loadChildren": ".\/pages\/default\/components\/sessions\/sessions.module#SessionsModule",

            },
            // end Sessions Component 

            //----------Force Redirection Starts--------------
            
            //  Twofactor Authentication component

            {
                "path": "registerBrowser",
                "canActivate": [AuthGuard],
                data: {
                    exRole: ['breg']
                },
                "loadChildren": ".\/pages\/default\/components\/twofactor-authentication\/twofactorauthentication.module#TwoFactorAuthenticationModule",

            },
            // end Twofactor Authentication  

            //  new user 2FA component
            
            {
                "path": "newuser",
                "canActivate": [AuthGuard],
                data: {
                    exRole: ['new']
                },
                "loadChildren": ".\/pages\/default\/components\/new-user-2FA\/new-user-2FA.module#NewUser2FAModule",

            },
            // end Twofactor Authentication  
            //-------------Force Redirection Ends----------------

            //  tester component

            {
                "path": "tester",
                "canActivate": [AuthGuard],
                data: {
                    // expectedRole: ['Manager', 'Pentester', 'Admin', 'Member'],
                    exRole:['tester']
                },
                "loadChildren": ".\/pages\/default\/components\/tester-component\/tester.module#TesterModule",

            },
            // end tester component  
            {
                "path": "add-whitelist",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Admin']
                },
                "loadChildren": ".\/pages\/default\/components\/add-whitelist\/add-whitelist.module#AddWhitelistModule",

            },
            {
                "path": "compare-vulnerabilities",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Manager']
                },
                "loadChildren": ".\/pages\/default\/components\/compare-issues\/compare-issues.module#CompareIssuesModule",

            },
            {
                "path": "add-alertMessage",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Admin', 'Manager']
                },
                "loadChildren": ".\/pages\/default\/components\/add-alertMessages\/add-alertMessages.module#AddAlertMessagesModule",
            },
            {
                "path": "add-munit",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Admin']
                },
                "loadChildren": ".\/pages\/default\/components\/add-munit\/add-munit.module#AddmUnitModule",
            },            
            {
                "path": "munit-list",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Admin']
                },
                "loadChildren": ".\/pages\/default\/components\/mUnit-list\/mUnit-list.module#MunitListModule",
            },
			{
                "path": "activate-license",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Manager','Member','Pentester']
                },
                "loadChildren": ".\/pages\/default\/components\/activate-license\/activate-license.module#ActivateLicenseModule",
            },
            {
                "path": "whitelist-ip",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Admin', 'Manager']
                },
                "loadChildren": ".\/pages\/default\/components\/whiteIP\/whiteIP.module#whiteIPModule"
            },
            {
                "path": "alertMessages-list",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Admin']
                },
                "loadChildren": ".\/pages\/default\/components\/alertMessages-list\/alertMessages-list.module#AlertMessagesListModule"
            },
            {
                "path": "users-list",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Super' ,'Admin']
                },
                "loadChildren": ".\/pages\/default\/components\/users-list\/users-list.module#UsersListModule"
            },
            {
                "path": "ips-list",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Admin', 'Manager']
                },
                "loadChildren": ".\/pages\/default\/components\/ips-list\/ips-list.module#ipsListModule"
            },
            {
                "path": "assign-munit",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Manager']
                },
                "loadChildren": ".\/pages\/default\/components\/assign-munit\/assign-munit.module#AssignMunitModule"
            }, 
            {
                "path": "manage-impact-type",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Admin']
                },
                "loadChildren": ".\/pages\/default\/components\/add-impact\/add-impact.module#AddImpactModule",

            }, 
            {
                "path": "costum-fields",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Admin']
                },
                "loadChildren": ".\/pages\/default\/components\/dynamic-c-fields/\dynamic-c-fields.module#DyanmicFieldModule",

            },
            {
                "path": "projects-list",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Super' ,'Admin']
                },
                "loadChildren": ".\/pages\/default\/components\/projects-list\/projects-list.module#ProjectsListModule"
            },
            {
                "path": "upload-file",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Pentester']
                },
                "loadChildren": ".\/pages\/default\/components\/fileupload\/fileupload.module#FileuploadModule"
            },
            {
                "path": "logging",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Super' ,'Admin', 'Manager', 'Member', 'Pentester']
                },
                "loadChildren": ".\/pages\/default\/components\/logging\/logging.module#LoggingModule"
            },
			{ 
                "path": "auto-technical-report/:key",
                //"canActivate": [AuthGuard],
				"loadChildren": ".\/pages\/default\/components\/auto-technical-report\/report.module#ReportModule"
            },
			{ 
                "path": "atuo-report-detail/:key/:pageNo",
                //"canActivate": [AuthGuard],
				"loadChildren": ".\/pages\/default\/components\/atuo-report-detail\/report.module#ReportModule"
            },
            {
                "path": "alerts",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Admin', 'Manager', 'Member']
                },
                "loadChildren": ".\/pages\/default\/components\/useralerts\/useralerts.module#userAlertsModule"
            },
            {
                "path": "issue-detail/:id",
                "canActivate": [AuthGuard],
                "loadChildren": ".\/pages\/default\/components\/issue-detail\/issue-detail.module#IssueDetailModule"
            },
            {
                "path": "view-all-issues",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Admin', 'Manager', 'Member']
                },
                "loadChildren": ".\/pages\/default\/components\/jiraissues\/jiraissues.module#JiraissuesModule"
            },
            {
                "path": "generate-report",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Manager', 'Member']
                },
                "loadChildren": ".\/pages\/default\/components\/generate-report\/report.module#ReportModule"
            },
            {
                "path": "normal-generate-report",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Manager', 'Member']
                },
                "loadChildren": ".\/pages\/default\/components\/normal-generate-report\/report.module#ReportModule"
            },
			{
                "path": "report-settings/:projectKey",
                "canActivate": [AuthGuard],
                data: {
                    expectedRole: ['Admin']
                },
                "loadChildren": ".\/pages\/default\/components\/report-settings\/report.module#ReportModule"
            },
            {
                "path": "header\/profile",
                "loadChildren": ".\/pages\/default\/header\/header-profile\/header-profile.module#HeaderProfileModule"
            },
            {
                "path": "404",
                "loadChildren": ".\/pages\/default\/not-found\/not-found.module#NotFoundModule"
            },
            {
                "path": "",
                "redirectTo": "index",
                "pathMatch": "full"
            }
        ]
    },
    {
        "path": "snippets\/pages\/errors\/error-6",
        "loadChildren": ".\/pages\/self-layout-blank\/snippets\/pages\/errors\/errors-error-6\/errors-error-6.module#ErrorsError6Module"
    },
    {
        "path": "**",
        "redirectTo": "404",
        "pathMatch": "full"
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ThemeRoutingModule { }