import { Injectable } from '@angular/core';
import { IssuesHelper } from '../helpers/issuesHelper';
import { MunitIssuesHelper } from '../helpers/munitIssuesHelper';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { filter, map, take } from 'rxjs/operators';
import { Observable, of } from "rxjs";
import { IP } from "../classes/ip";
import { Component } from '@angular/core';


@Injectable()
export class IssuesService {
		
    constructor(private http: HttpClient, private IssuesHelper: IssuesHelper) {
		
    }	
	
   getManagerAllIssues(): Observable<any> {
        return this.http.get('/api/ManagerallIssues', this.jwt()).pipe(map((result) => {
            return result;
        }));
    }
    /**
     * Options are in formate{date:{ from:Date, to:Date}, mUnit: mUnit Name, IP, OS, IT:Impact Type, VR:Vulnerability Rating} 
     * */
    getManagerIssueswithDateAndmUnit(Options): Observable<any> {
        // console.log(Options);
      
        return this.http.post('/api/ManagerIssueswithDateAndmUnit/',{Options : JSON.stringify( Options )}, this.jwt()).pipe(map((result) => {
            return result;
        }));
    }
    getProjectData(projectName): Observable<any> {

        return this.http.get('/api/getProjectData/' + projectName, this.jwt()).pipe(map((result) => {
            
            return result
        }));
    }
	autoTechnicalReport(projectKey): Observable<any> {

        return this.http.get('/api/autoReportByProjectKey/' + projectKey).pipe(map((result) => {
            
            return result
        }));
    }
	autoReportDetail(projectKey,pageNo): Observable<any> {

        return this.http.get('/api/autoReportDetail/' + projectKey+'/'+pageNo).pipe(map((result) => {
            
            return result
        }));
    }
    getManagerAllIssuesforDash(): Observable<any> {
        return this.http.get('/api/ManagerallIssuesforDash', this.jwt()).pipe(map((result) => {
            return result;
        }));
    }
    /**
     * Options are in formate{date:{ from:Date, to:Date}, mUnit: mUnit Name, IP, OS, IT:Impact Type, VR:Vulnerability Rating} 
     * */
    getManagerIssueswithDateAndmUnitforDash(Options): Observable<any> {
        // console.log(Options);
      
        return this.http.post('/api/ManagerIssueswithDateAndmUnitforDash/', {Options: JSON.stringify( Options )}, this.jwt()).pipe(map((result) => {
            return result;
        }));
    }
    // new timeline....
    getProjectDataforDashNewTimeline(projectName): Observable<any> {

        return this.http.get('/api/getProjectDataforDashNewTimeline/' + projectName, this.jwt()).pipe(map((result) => {            
            return result
        }));
    }
    getProjectDataforDashNewTimelineWithLimit(projectKey,skipRec,limitRec): Observable<any> {
       
        return this.http.get('/api/getProjectDataforDashNewTimelineWithLimit/' + projectKey+'/' + skipRec + '/' + limitRec, this.jwt()).pipe(map((result) => {
            return result;
        }));
    }
    getManagerAllIssuesforDashNewTimeline(Options): Observable<any> {
        return this.http.post('/api/ManagerIssueswithDateAndmUnitforDashNewTimeline', {Options: JSON.stringify( Options )}, this.jwt()).pipe(map((result) => {
            return result;
        }));
    }
	getManagerConsolidatedDB(): Observable<any> {
        return this.http.post('/api/managerConsolidatedDB', {Options: JSON.stringify( {} )}, this.jwt()).pipe(map((result) => {
            return result;
        }));
    }
	
    /**
     * Options are in formate{date:{ from:Date, to:Date}, mUnit: mUnit Name, IP, OS, IT:Impact Type, VR:Vulnerability Rating} 
     * */
    ManagerIssueswithDateAndmUnitforDashNewTimeline(Options): Observable<any> {
        // console.log(Options);
      
        return this.http.post('/api/ManagerIssueswithDateAndmUnitforDashNewTimeline/', {Options: JSON.stringify( Options )}, this.jwt()).pipe(map((result) => {
            return result;
        }));
    }
    membermUnitIssues(Options): Observable<any> {
        // console.log(Options);
      
        return this.http.post('/api/memberConsolidatedDB/', {Options: JSON.stringify( Options )}, this.jwt()).pipe(map((result) => {
            return result;
        }));
    }
	

    ManagerIssueswithDateAndmUnitforDashNewTimelineMultipReq(Options,): Observable<any> {
        // console.log(Options);
      
        return this.http.post('/api/ManagerIssueswithDateAndmUnitforDashNewTimeline/', {Options: JSON.stringify( Options )}, this.jwt()).pipe(map((result) => {
            return result;
        }));
    }
    //new timeline End/
    getProjectDataforDash(projectName): Observable<any> {

        return this.http.get('/api/getProjectDataforDash/' + projectName, this.jwt()).pipe(map((result) => {            
            return result
        }));
    }
    getManagerDataWithoutIssues(): Observable<any> {
        return this.http.get('/api/ManagerallIssueslight', this.jwt()).pipe(map((result) => {
            return result;
        }));
    }
	loadReportPreRequsites(): Observable<any> {
        return this.http.get('/api/loadReportPreRequsites', this.jwt()).pipe(map((result) => {
            return result;
        }));
    }
	reportPreRequsitesByProjectKey(projectKey): Observable<any> {
        return this.http.get('/api/reportPreRequsites/'+projectKey, this.jwt()).pipe(map((result) => {
            return result;
        }));
    }
    /**
     * Options are in formate{date:{ from:Date, to:Date}, mUnit: mUnit Name, IP, OS, IT:Impact Type, VR:Vulnerability Rating} 
     * */
    getManagerDatawithDateAndmUnitWithoutIssues(Options): Observable<any> {
        // console.log(Options);
      
        return this.http.post('/api/ManagerIssueswithDateAndmUnitlight', {Options: JSON.stringify( Options )}, this.jwt()).pipe(map((result) => {
            return result;
        }));
    }
	compareDashboard(Options): Observable<any> {
        // console.log(Options);
      
        return this.http.post('/api/compareDashboard', {Options: JSON.stringify( Options )}, this.jwt()).pipe(map((result) => {
            return result;
        }));
    }

    generalReportData(Options={}): Observable<any> {
       // console.log(Options);
     
       return this.http.post('/api/generalReportData', {Options: JSON.stringify( Options )}, this.jwt()).pipe(map((result) => {
           return result;
       }));
   }

    mUnitandDateIssuesforGeneralReport(Options={}): Observable<any> {
         console.log(Options);
      
        return this.http.post('/api/mUnitandDateIssuesforGeneralReport', {Options: JSON.stringify( Options )}, this.jwt()).pipe(map((result) => {
            return result;
        }));
    }
    
    getMunitAllIssues(munit,info=1): Observable<any> {
        return this.http.get('/api/ManagerallIssues', this.jwt()).pipe(map((result) => {

            let preResponse: any = null;
            this.IssuesHelper.informationalSpliter(result,info).subscribe(res => {
                preResponse = null;
                preResponse = res;
            });

            let _response = null;
            this.IssuesHelper.mUnitIssues(preResponse, munit).subscribe(res => {
                _response = null;
                _response = res;
            });

            let response: any = null;
            let withDone:any = _response;
            this.IssuesHelper.doneFilter(_response).subscribe(res => {
                response = null;
                response = res;
            });


            let bi = this.IssuesHelper.BusinessImpactGraphData(response);
            let uniqueIps = this.IssuesHelper.UniqueIPs(response);
            let osArray = this.IssuesHelper.SortedOS(response);
            let owasp = this.IssuesHelper.OWASP(response);
            let testType = this.IssuesHelper.SortedTestType(response);
            let cvss = this.IssuesHelper.CVSS(response);
			
            let vulnerabilityType = this.IssuesHelper.SortedImpactType(response);
            // let issueStatus = this.IssuesHelper.IssueStatus(response);
            // let timeline = this.IssuesHelper.Timeline(response);
            let issueStatus = this.IssuesHelper.IssueStatus(withDone);
            let timeline = this.IssuesHelper.Timeline(withDone);
            let allIssues = response;
            let allIssuesWithDone = withDone;
            let avgRisk = this.IssuesHelper.AverageRisk(response);
            let actor = this.IssuesHelper.Actor(response);
            let vector = this.IssuesHelper.Vector(response);
            let ciaDamage = this.IssuesHelper.CIADamage(response);
            let severity = this.IssuesHelper.VulnerabilitiesBySeverity(response);

            let Issues = {
                All: allIssues,
                AllWithDone: allIssuesWithDone,
                Timeline: timeline,
                IssueStatus: issueStatus,
                VulnerabilityType: vulnerabilityType,
                CVSS: cvss,
                TestType: testType,
                OWASP: owasp,
                OS: osArray,
                UniqueIps: uniqueIps,
                BI: bi,
                Risk: avgRisk,
                Actor: actor,
                Vector: vector,
                CIADamage: ciaDamage,
                Severity: severity

            }
            return Issues
        }));
    }
    filterWithMunitIssues(issues, munit, info=1) {
        
        let preResponse: any = null;
        this.IssuesHelper.informationalSpliter(issues,info).subscribe(res => {
            preResponse = null;
            preResponse = res;
        });

        let _response = null;
        this.IssuesHelper.mUnitIssues(preResponse, munit).subscribe(res => {
            _response = res;
        });        

        let response: any = null;
        let withDone: any = _response;
        this.IssuesHelper.doneFilter(_response).subscribe(res => {
            response = null;
            response = res;
        });

        let bi = this.IssuesHelper.BusinessImpactGraphData(response);
        let uniqueIps = this.IssuesHelper.UniqueIPs(response);
        let osArray = this.IssuesHelper.SortedOS(response);
        let owasp = this.IssuesHelper.OWASP(response);
        let testType = this.IssuesHelper.SortedTestType(response);
        let cvss = this.IssuesHelper.CVSS(response);
        let vulnerabilityType = this.IssuesHelper.SortedImpactType(response);
        let issueStatus = this.IssuesHelper.IssueStatus(withDone);
        let timeline = this.IssuesHelper.Timeline(withDone);
        let allIssues = response;
        let avgRisk = this.IssuesHelper.AverageRisk(response);
        let actor = this.IssuesHelper.Actor(response);
        let vector = this.IssuesHelper.Vector(response);
        let ciaDamage = this.IssuesHelper.CIADamage(response);
        let severity = this.IssuesHelper.VulnerabilitiesBySeverity(response);

        let Issues = {
            All: allIssues,
            Timeline: timeline,
            IssueStatus: issueStatus,
            VulnerabilityType: vulnerabilityType,
            CVSS: cvss,
            TestType: testType,
            OWASP: owasp,
            OS: osArray,
            UniqueIps: uniqueIps,
            BI: bi,
            Risk: avgRisk,
            Actor: actor,
            Vector: vector,
            CIADamage: ciaDamage,
            Severity: severity

        }
        return Issues
    }
    prepareChartData(_response,info=1) {
           
        let preResponse: any = null;
        this.IssuesHelper.informationalSpliter(_response,info).subscribe(res => {
            preResponse = null;
            preResponse = res;
        });

        let response: any = null;
        let withDone: any = preResponse;
        this.IssuesHelper.doneFilter(preResponse).subscribe(res => {
            response = null;
            response = res;
        });
        let bi = this.IssuesHelper.BusinessImpactGraphData(response);
        let uniqueIps = this.IssuesHelper.UniqueIPs(response);
        let osArray = this.IssuesHelper.SortedOS(response);
        let owasp = this.IssuesHelper.OWASP(response);
        let testType = this.IssuesHelper.SortedTestType(response);
        let cvss = this.IssuesHelper.CVSS(response);
        let vulnerabilityType = this.IssuesHelper.SortedImpactType(response);
        let issueStatus = this.IssuesHelper.IssueStatus(withDone);
        let timeline = this.IssuesHelper.Timeline(withDone);
        let allIssues = response;
        let allIssuesWithDone = withDone;
        let avgRisk = this.IssuesHelper.AverageRisk(response);
        let actor = this.IssuesHelper.Actor(response);
        let vector = this.IssuesHelper.Vector(response);
        let ciaDamage = this.IssuesHelper.CIADamage(response);
        let severity = this.IssuesHelper.VulnerabilitiesBySeverity(response);

        let Issues: any = {};
        Issues = {
            All: allIssues,
            AllWithDone: allIssuesWithDone,
            Timeline: timeline,
            IssueStatus: issueStatus,
            VulnerabilityType: vulnerabilityType,
            CVSS: cvss,
            TestType: testType,
            OWASP: owasp,
            OS: osArray,
            UniqueIps: uniqueIps,
            BI: bi,
            Risk: avgRisk,
            Actor: actor,
            Vector: vector,
            CIADamage: ciaDamage,
            Severity: severity

        }
        return Issues;
    }
    _filterIssueOnDate(issues, date, info=1) {
           
        let preResponse: any = null;
        this.IssuesHelper.informationalSpliter(issues,info).subscribe(res => {
            preResponse = null;
            preResponse = res;
        });

        let _response = null;
        _response = this.IssuesHelper.filterDateIssues(preResponse, date);

        let response: any = null;
        let withDone: any = _response;
        this.IssuesHelper.doneFilter(_response).subscribe(res => {
            response = null;
            response = res;
        });
        // console.log(_response);

        let bi = this.IssuesHelper.BusinessImpactGraphData(response);
        let uniqueIps = this.IssuesHelper.UniqueIPs(response);
        let osArray = this.IssuesHelper.SortedOS(response);
        let owasp = this.IssuesHelper.OWASP(response);
        let testType = this.IssuesHelper.SortedTestType(response);
        let cvss = this.IssuesHelper.CVSS(response);
        let vulnerabilityType = this.IssuesHelper.SortedImpactType(response);
        let issueStatus = this.IssuesHelper.IssueStatus(withDone);
        let timeline = this.IssuesHelper.Timeline(withDone);
        let allIssues = response;
        let avgRisk = this.IssuesHelper.AverageRisk(response);
        let actor = this.IssuesHelper.Actor(response);
        let vector = this.IssuesHelper.Vector(response);
        let ciaDamage = this.IssuesHelper.CIADamage(response);
        let severity = this.IssuesHelper.VulnerabilitiesBySeverity(response);

        let Issues = {
            All: allIssues,
            Timeline: timeline,
            IssueStatus: issueStatus,
            VulnerabilityType: vulnerabilityType,
            CVSS: cvss,
            TestType: testType,
            OWASP: owasp,
            OS: osArray,
            UniqueIps: uniqueIps,
            BI: bi,
            Risk: avgRisk,
            Actor: actor,
            Vector: vector,
            CIADamage: ciaDamage,
            Severity: severity

        }
        return Issues
    }   
    filterIssueOnDate(issues, date,info=1) {
           
        let preResponse: any = null;
        this.IssuesHelper.informationalSpliter(issues,info).subscribe(res => {
            preResponse = null;
            preResponse = res;
        });

        let _response = null;
        _response = this.IssuesHelper.filterIssueOnDate(date, preResponse);

        let response: any = null;        
        let withDone: any = _response.issue1;
        let withDone2: any = _response.issue2;
        this.IssuesHelper.doneFilter(withDone).subscribe(res => {
            response = null;
            response = res;
        });
        // console.log(_response);

        let bi = this.IssuesHelper.BusinessImpactGraphData(response);
        let uniqueIps = this.IssuesHelper.UniqueIPs(response);
        let osArray = this.IssuesHelper.SortedOS(response);
        let owasp = this.IssuesHelper.OWASP(response);
        let testType = this.IssuesHelper.SortedTestType(response);
        let cvss = this.IssuesHelper.CVSS(response);
        let vulnerabilityType = this.IssuesHelper.SortedImpactType(response);
        let issueStatus = this.IssuesHelper.IssueStatus(withDone2);
        let timeline = this.IssuesHelper.datewiseTimeline(withDone,date);
        let allIssues = response;
        let avgRisk = this.IssuesHelper.AverageRisk(response);
        let actor = this.IssuesHelper.Actor(response);
        let vector = this.IssuesHelper.Vector(response);
        let ciaDamage = this.IssuesHelper.CIADamage(response);
        let severity = this.IssuesHelper.VulnerabilitiesBySeverity(response);

        let Issues = {
            All: allIssues,
            Timeline: timeline,
            IssueStatus: issueStatus,
            VulnerabilityType: vulnerabilityType,
            CVSS: cvss,
            TestType: testType,
            OWASP: owasp,
            OS: osArray,
            UniqueIps: uniqueIps,
            BI: bi,
            Risk: avgRisk,
            Actor: actor,
            Vector: vector,
            CIADamage: ciaDamage,
            Severity: severity

        }
        return Issues
    }

	
    private jwt() {
        // create authorization header with jwt token
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {
            let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + currentUser.token });
            return { headers: headers };
        }
    }
}