// theme.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  lightModeClasses = {
    headerColor: "m-brand--skin-dark",
    headerCenter: "lightbg",
    headerCenterBB: "lightbg white-bottom1px-border",
    headerRight: "lightbg",
    headerRightColor: "whitec",
    themeModeColor: "whitec themeDisplay",
    dasbhoardBg:"lightgreybg white-left2px-border",
    dashboardCenter: "lightgreybg",
    dashboardGrahpArea: "lightgreybg non-left-border-light",
    dashboardGrahpHeading: "graph-heading-light",
	dashboardCriticalHeading: "graph-heading-light criticalities",
    dashboardHColor: "darkc",
    dashboardCriticalityItem: "criticality_item darkc",
    dashboardHColorWithGraphHeading: "graph-heading-light darkc",
    dashboar2pxRBorder: "white-right2px-border",
    dashboar2pxLBorder: "white-left2px-border",
    dashboar1pxBBorder: "white-bottom1px-border",
    dashboar2pxTBorder: "white-top2px-border",
    avBoxes: "alarms__by__intent_days_light",
    ciaBorder: "lightgreybg non-left-border-light",
    crBoxes: "white-border",
    birBoxes: "white-border",
    crBBoxes: "card-light lightgreybg",
    bordersWithoutLeft: "non-left-border-light",
    topLeft: "m-brand--skin-light",
    m_ver_menu: "stickyleftmenu m-aside-left--skin-light m-aside-menu--submenu-skin-dark",
    leftMenu: "m-aside-left--skin-light",
    leftMenuIcons: "m-menu__link-icon-light",
    avgraph: "grey-section-light",
    companyRiskCard: "bglight",
    companyRiskProgress: "progress",
    noneLeftBorder: "non-left-border-light",
    onlyRightBorder: "border-right-light",
    generalText: "border-right-light",
    graphHeadingStyling: "graphHeadingStylinglight",
    isDarkMode:false
  };

  darkModeClasses = {
    headerColor: "m-brand--skin-dark-lblk",
    headerCenter: "darkblackgb",
    headerCenterBB: "darkblackgb black-bottom1px-border",
    headerRight: "darkblackgb",
    headerRightColor: "whitec",
    themeModeColor: "whitec themeDisplay",
    dasbhoardBg:"darkblackgb black-left2px-border",
    dashboardCenter: "darkblackgb",
    dashboardGrahpArea: "darkblackgb non-left-border-blk",
    dashboardGrahpHeading: "graph-heading-dark",
	dashboardCriticalHeading: "graph-heading-light criticalities",
    dashboardHColor: "whitec",
    dashboardCriticalityItem: "criticality_item whitec",
    dashboardHColorWithGraphHeading: "graph-heading-dark whitec",
    dashboar2pxRBorder: "black-right2px-border",
    dashboar2pxLBorder: "black-left2px-border",
    dashboar1pxBBorder: "black-bottom1px-border",
    dashboar2pxTBorder: "black-top2px-border",
    avBoxes: "alarms__by__intent_days",
    ciaBorder: "darkblackgb non-left-border-blk",
    crBoxes: "black-border",
    birBoxes: "black-border",
    crBBoxes: "card darkblackgb",
    bordersWithoutLeft: "non-left-border-blk",    
    topLeft: "m-brand--skin-dark-blk darkblackgb",
    leftMenu: "m-aside-left--skin-dark-blk",
    m_ver_menu: "stickyDarkleftmenu m-aside-left--skin-dark-blk m-aside-menu--submenu-skin-dark",
    leftMenuIcons: "m-menu__link-icon",
    avgraph: "grey-section-dark",
    companyRiskCard: "bgblack",
    companyRiskProgress: "progress-dark",
    noneLeftBorder: "non-left-border-blk",
    onlyRightBorder: "border-right-dark",
    generalText: "border-right-light",
    graphHeadingStyling: "graphHeadingStylingdark",
    isDarkMode:true
  };

  // Holds the current theme mode classes
  private currentMode = new BehaviorSubject(this.lightModeClasses);
  currentMode$ = this.currentMode.asObservable();

  toggleMode(isDarkMode: boolean): void {
    this.currentMode.next(isDarkMode ? this.darkModeClasses : this.lightModeClasses);
  }

  getModeClasses(): any {
    return this.currentMode.getValue();
  }
}
