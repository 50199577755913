
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { filter, map,take, catchError } from 'rxjs/operators';
import { Observable, of } from "rxjs";
import { IP } from "../classes/ip";
import { GlobalErrorHandler } from './error-handler.service';


@Injectable()
export class JiraService {
    
    constructor(private http: HttpClient,private errorService:GlobalErrorHandler) {
    }

    getIssueDetail(key: String): Observable<any> {
        return this.http.get('/api/IssueDetail/' + key, this.jwt()).pipe(map((response) => response));
    }
    getIssueCount(): Observable<any> {
        return this.http.get('/api/issuesCount/', this.jwt()).pipe(map((response) => response));
    }
	getUserProjectSettings(token): Observable<any> {
        return this.http.get('/api/getProjectSettingByUser/' + token, this.jwt()).pipe(map((response) => response));
    }
	activateLiense(data): Observable<any> {
        return this.http.post('/api/activateLicense/',data, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),
        map((response) => response));
    }
	getConsolidatedDB(projectName): Observable<any> {
        return this.http.get('/api/consolidatedDashboard/'+projectName, this.jwt()).pipe(map((response) => response));
    }
	getManagerConsolidatedDB(Options): Observable<any> {
        return this.http.post('/api/managerConsolidatedDB', {Options: JSON.stringify( {Options} )}, this.jwt()).pipe(map((result) => {
            return result;
        }));
    }
    myMunitsList():Observable<any> {
        return this.http.get('/api/myMunits/', this.jwt()).pipe(map((response) => response));
    }
    
    addComments(data): Observable<any> {
        return this.http.post('/api/JiraAddcommentToissue', data, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),
          map((response) => response));
    }
	
	getUserProjectList(): Observable<any>{
		return this.http.get('/api/userProjectsList', this.jwt()).pipe(map((response) => response));
	}
	
    getProjectsList(): Observable<any> {
        return this.http.get('/api/ProjectsList', this.jwt()).pipe(map((response) => response));
    }
	getAssetInventories(): Observable<any> {
        return this.http.get('/api/assetInventoryList', this.jwt()).pipe(map((response) => response));
    }
    UpdateProject(project): Observable<any> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let options = { headers: headers };
        return this.http.post('/api/UpdateProject', JSON.stringify(project), this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),
    map((response) => response));

    }
    UpdateProjectsList(): Observable<any> {
        return this.http.get('/api/UpdateProjectsList', this.jwt()).pipe(map((response) => response));
    }
    UpdateProjectsUsers(): Observable<any> {
        return this.http.get('/api/UpdateProjectUsers', this.jwt()).pipe(map((response) => response));
    }

    getAllIssues(): Observable<any> {
        return this.http.get('/api/allIssues', this.jwt()).pipe(map((response) => response));
    }
    //Get User All Issues For Admin
    getUserAllIssues(username, projectName): Observable<any> {
        return this.http.get('/api/UserallIssues/' + username + '/' + projectName, this.jwt()).pipe(map((response) => response));
    }
    getManagerAllIssues(): Observable<any> {
        return this.http.get('/api/ManagerallIssues', this.jwt()).pipe(map((response) => response));
    }

    getManagerIssueswithDateAndmUnitforDash(Options): Observable<any> {
        console.log(Options);
      
        return this.http.post('/api/ManagerIssueswithDateAndmUnitforDash/', {Options: JSON.stringify( Options )}, this.jwt()).pipe(map((result) => {
            return result;
        }));
    }
    //Get User OnGoing Issues For Admin
    getUseronGoingIssues(userName, projectName): Observable<any> {
        return this.http.get('/api/onUserGoingIssues/' + userName + '/' + projectName, this.jwt()).pipe(map((response) => response));

    }

    getDoneIssues(): Observable<any> {
        return this.http.get('/api/DoneIssues', this.jwt()).pipe(map((response) => response));
    }
    //Get User Done Issues For Admin
    getUserDoneIssues(userName, projectName): Observable<any> {
        return this.http.get('/api/UserDoneIssues/' + userName + '/' + projectName, this.jwt()).pipe(map((response) => response));
    }
    getToDoIssues(): Observable<any> {
        return this.http.get('/api/ToDoIssues', this.jwt()).pipe(map((response) => response));
    }
    //Get User Todo Issues For Admin
    getUserToDoIssues(userName, projectName): Observable<any> {
        return this.http.get('/api/UserToDoIssues/' + userName + '/' + projectName, this.jwt()).pipe(map((response) => response));
    }

    getBugIssues(): Observable<any> {
        return this.http.get('/api/BugIssues', this.jwt()).pipe(map((response) => response));
    }
    //Get User Bugs Issues For Admin
    UsergetBugIssues(userName, projectName): Observable<any> {
        return this.http.get('/api/UserBugIssues/' + userName + '/' + projectName, this.jwt()).pipe(map((response) => response));
    }
    getEpicIssues(): Observable<any> {
        return this.http.get('/api/EpicIssues', this.jwt()).pipe(map((response) => response));
    }
    //Get User Bugs Issues For Admin
    UsergetEpicIssues(userName, projectName): Observable<any> {
        return this.http.get('/api/UserEpicIssues/' + userName + '/' + projectName, this.jwt()).pipe(map((response) => response));
    }
    getStoryIssues(): Observable<any> {
        return this.http.get('/api/StoryIssues', this.jwt()).pipe(map((response) => response));
    }
    //Get User Bugs Issues For Admin
    UsergetStoryIssues(userName, projectName): Observable<any> {
        return this.http.get('/api/UserStoryIssues/' + userName + '/' + projectName, this.jwt()).pipe(map((response) => response));
    }
    getTaskIssues(): Observable<any> {
        return this.http.get('/api/TaskIssues', this.jwt()).pipe(map((response) => response));
    }
    //Get User Bugs Issues For Admin
    UsergetTaskIssues(userName, projectName): Observable<any> {
        return this.http.get('/api/UserTaskIssues/' + userName + '/' + projectName, this.jwt()).pipe(map((response) => response));
    }
    getSubTaskIssues(): Observable<any> {
        return this.http.get('/api/SubTaskIssues', this.jwt()).pipe(map((response) => response));
    }
    //Get User Bugs Issues For Admin
    UsergetSubTaskIssues(userName, projectName): Observable<any> {
        return this.http.get('/api/UserSubTaskIssues/' + userName + '/' + projectName, this.jwt()).pipe(map((response) => response));
    }
    getAlerts(): Observable<any> {
        return this.http.get('/api/getAlerts', this.jwt()).pipe(map((response) => response));
    }
    getLogging(): Observable<any> {
        return this.http.get('/api/getLogging', this.jwt()).pipe(map((response) => response));
    }
    getUserLogging(): Observable<any> {
        return this.http.get('/api/getUserLogging', this.jwt()).pipe(map((response) => response));
    }

    //Vulnernability issues

    getCriticalData(ProjectName): Observable<any> {
        return this.http.get('/api/getcriticalIssues/' + ProjectName, this.jwt()).pipe(map((response) => response));

    }
    getMediumData(ProjectName): Observable<any> {
        return this.http.get('/api/getMediumData/' + ProjectName, this.jwt()).pipe(map((response) => response));

    }
    getlowData(ProjectName): Observable<any> {
        return this.http.get('/api/getlowData/' + ProjectName, this.jwt()).pipe(map((response) => response));

    }

    getDataForTimeLineGraph(ProjectName): Observable<any> {
        return this.http.get('/api/getDataForTimeLineGraph/' + ProjectName, this.jwt()).pipe(map((response) => response));
    }
    getDataForManagerTimeLineGraph(): Observable<any> {
        return this.http.get('/api/getDataForManagerTimeLineGraph', this.jwt()).pipe(map((response) => response));
    }

    getSecurityBox(): Observable<any> {
        return this.http.get('/api/getSecurityBox', this.jwt()).pipe(map((response) => response));
    }

    getDoneCount(projectName,IssueStatus): Observable<any> {
        return this.http.get('/api/getDoneCount', this.jwt()).pipe(map((response) => {
            let newData: any = {};
            Object.assign(newData,IssueStatus);
            Object.assign(newData,{'Closed':response});

            return newData;
        }));
    }

    uploadImage(imageData): Observable<any> {
		  let currentUser = JSON.parse(localStorage.getItem('currentUser'));

        if (currentUser && currentUser.token) {
            var headers = new HttpHeaders({
                'Authorization': 'Bearer ' + currentUser.token
            });

        }
        let options = { headers: headers };
        return this.http.post('/api/fileUpload', imageData, options).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),
    map((response) => response));
    }
    // uploadImage(imageData) {
    //     return this.http.post('/api/fileUpload', imageData, this.jwt()).map((response: Response) => {
    //         let resp = response.json()
    //         //Call helper to build the data
    //         // const data = Helper.buildData(resp);


    //         // return data;
    //     });
    // }
    getProjectData(projectName): Observable<any> {

        return this.http.get('/api/getProjectData/' + projectName, this.jwt()).pipe(map((response) => response));
    }

    getUserAlerts(userName): Observable<any> {

        return this.http.get('/api/getUserAlerts/' + userName, this.jwt()).pipe(map((response) => response));
    }
    addIP(newIp: IP): Observable<any> {

        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let options = { headers: headers };
        return this.http.get("/api/addIP/" + newIp.ip, this.jwt()).pipe(map((response) => response));

    }

    getwhiteIPList(): Observable<any> {
        return this.http.get("/api/whiteIPList", this.jwt()).pipe(map((response) => response));
    }
    reValidateIp(ip): Observable<any> {
        return this.http.get("/api/revalidate/" + ip._id, this.jwt()).pipe(map((response) => response));

    }
    editWhiteIP(updatedIP: IP): Observable<any> {
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let options = { headers: headers };
        return this.http.post("/api/editIP", JSON.stringify(updatedIP), this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),
    map((response) => response));
    }
    WhiteIPById(id): Observable<any> {

        return this.http.get("/api/WhiteIPById/" + id, this.jwt()).pipe(map((response) => response));
    }
    DeleteWhiteIP(id): Observable<any> {
        return this.http.get("/api/deleteWhiteIP/" + id, this.jwt()).pipe(map((response) => response));
    }
    //costumfields
    getAllcostumFields(): Observable<any> {
        return this.http.get("/api/getCostumfields/", this.jwt()).pipe(map((response) => response));
    }
    getCostumField(id): Observable<any> {
        return this.http.post("/api/getCostumfield/", id, this.jwt()).pipe(map((response) => response));
    }
    UpdatecostumField(id): Observable<any> {
        return this.http.post("/api/updateCostumfield/", id, this.jwt()).pipe(map((response) => response));
    }
    private jwt() {
        // create authorization header with jwt token
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {
            let headers = new HttpHeaders({
                'Authorization': 'Bearer ' + currentUser.token,
                'Content-Type': 'application/json'

            });
            return { headers: headers };
        }
    }

}