
import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { filter, map, take, catchError, delay } from 'rxjs/operators';
import { Observable, of, throwError } from "rxjs";
import { GlobalErrorHandler } from './error-handler.service';


@Injectable()
export class AlienVaultService {
    
   
    authToken: String;
    user_pass: String;
    username: String;
    password: String;
    MethodToGenerateToken : any;
    authUrl = "https://airtel-tigo.alienvault.cloud/api/2.0/oauth/token?grant_type=client_credentials";
    alarmUrl = 'https://airtel-tigo.alienvault.cloud/api/2.0/alarms'

    constructor(private http: HttpClient,private errorService:GlobalErrorHandler) {
        this.user_pass = '';
        this.username = '';
        this.password = '';
        this.authToken = '';
        this.MethodToGenerateToken = null;
    }

    // impactList(): Observable<any> {
    //     return this.http.get("/api/impactList/", this.jwt()).pipe(map((response) => response));
    // }
    // addImpact(impact: Impact): Observable<any> {
    //     return this.http.post("/api/addImpact/", JSON.stringify(impact), this.jwt()).pipe(catchError(
    //         error => of(this.errorService.errorHttp(error))
    //       ),
    // map((response) => response));
    // }

    //getAllcountry post
  
    

    
    getCountries(): Observable<any> {
        return this.http.post("/api/getAllcountry/", {}, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),
        map((response) => response));
    }
		
    updatesProjectSettings(pk ,data) :Observable<any>{
        return this.http.post("/api/UpdateProjectSettings/"+pk, data, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),
        map((response) => response));
    }
	updatesProjectLicenseSettings(pk ,data) :Observable<any>{
        return this.http.post("/api/generateLicense/"+pk, data, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),
        map((response) => response));
    }
	
	updateProjectAssetRisk(pk ,data) :Observable<any>{
		return this.http.post("/api/UpdateProjectTypeSettings/"+pk, data, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),
        map((response) => response));
    }
	
    getProjectSettingsByKey(key) : Observable<any>{
        
        return this.http.post("/api/getProjectByKey/", {key}, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),
        map((response) => response));
    }

    getCalanderSettingByKey(data){
        return this.http.post("/api/getProjectCalanderByKey/", data, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),
        map((response) => response));
    }

    
    private jwt() {
        // create authorization header with jwt token
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {
            let headers = new HttpHeaders({
                'Authorization': 'Bearer ' + currentUser.token,
                'Content-Type': 'application/json',
            });
            return { headers: headers };
        }
    }
 

    AuthToken(user_passString:string){

    }

    AuthTokenProjectName(projectName=undefined){

        return this.http.post('/api/av/token',{projectName},this.jwt())
        .pipe(catchError(this.handleError),map(res=>{if(!res["error"]){ this.authToken = res+"" }; return res}))
    }
    
    AuthTokenUsernamePassword(username,password){
        return this.http.post('/api/av/token',{username,password},this.jwt())
        .pipe(catchError(this.handleError)).subscribe(res=>{if(!res["error"]){ this.authToken = res+"" }})
    }

    AuthTokenUsernamePasswordObs(username,password):Observable <any>{
        return this.http.post('/api/av/token',{username,password},this.jwt())
        .pipe(catchError(this.handleError),map((res) =>{if(!res["error"]){ this.authToken = res+"" }}))
    }


    issuesWithIPs(ip,munit){
        return this.http.post('/api/issuesWithIPs',{token:this.authToken,ip:ip,munit:munit}, this.jwt())
		.toPromise();
    }
	issuesWithIPsWOP(ip,munit){
        return this.http.post('/api/issuesWithIPs',{token:this.authToken,ip:ip,munit:munit}, this.jwt());
    }

    AVAlarms2(Options, projectName){
        // console.log("in Option");
        
        return this.http.post('/api/av/alarmswithOption',{token:this.authToken,Options:Options,projectName},this.jwt())
        // .pipe(catchError(this.handleError))
        .toPromise()
        // .pipe(map(res=>{if(!res["error"]){ console.log(res);
        //  this.authToken = res+"" }}))alarmswithOption
    }

    AVAlarmbyID(id,projectName){
       // console.log("in uuid alarm");
        
        return this.http.post('/api/av/alarm',{token:this.authToken,id,projectName},this.jwt())
        // .pipe(catchError(this.handleError))
        .toPromise()
        // .pipe(map(res=>{if(!res["error"]){ console.log(res);
        //  this.authToken = res+"" }}))alarmswithOption
    }

    AVAlarms3(url){
        //console.log("in Option");
        
        return this.http.post('/api/av/alarmswithurl',{token:this.authToken,url},this.jwt())
        .pipe(catchError(this.handleError)).toPromise()
        // .pipe(map(res=>{if(!res["error"]){ console.log(res);
        //  this.authToken = res+"" }}))alarmswithOption
    }


    AuthTokenUserPassHash(hash) {
        this.user_pass = hash;
        // this.MethodToGenerateToken = this.AV1(hash);
    
        this.http
          .post(this.authUrl, {}, this.AV1(hash))
          .pipe(catchError(this.handleError))
          .subscribe(res => {
            //console.log('res');
            //console.log(res);
            //console.log('-----END-----');
          });
      }

    private AV1(user_pass){
        const headers = new HttpHeaders({
            'Authorization': 'Basic ' + user_pass
          })
        return {headers:headers}
    }

    handleError(error: HttpErrorResponse) {
        let errorMessage = 'Unknown error!';
        console.dir(error);
        if ((error.error instanceof ErrorEvent )) {
          // Client-side errors
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // Server-side errors
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        // window.alert(errorMessage);
        return throwError(errorMessage);
      }


}

